import {
  GET_DASHBOARD_TOP_DISPERSIONS,
  SET_DASHBOARD_STATE,
  SET_DASHBOARD_ALERT,
  GET_DASHBOARD_WATCHLIST,
  GET_DASHBOARD_WATCHLIST_SET,
  GET_DASHBOARD_TRADE_LOGS,
  ADD_DASHBOARD_TICKER,
  ADD_DASHBOARD_TRADE_LOG,
  GET_DASHBOARD_RELATED_PAIRS,
  GET_DASHBOARD_TRADES,
  DELETE_DASHBOARD_TRADE,
  UPDATE_DASHBOARD_TRADE,
  GET_DASHBOARD_FAVORITES,
  DELETE_DASHBOARD_FAVORITE,
  UPDATE_DASHBOARD_FAVORITE,
  DELETE_DASHBOARD_TRADE_LOG,
  UPDATE_DASHBOARD_TRADE_LOG,
} from '../actions/types';

const initialState = {
  // [topdispersions]
  dashboardTopDispersionsData: null,
  loadingDashboardTopDispersions: true,
  dashboardTopDispersionsActiveTimePeriod: 1,
  dashboardTopDispersionsActiveSort: { medianReturn: 'desc' },
  dashboardTopDispersionsActiveFilters: localStorage.getItem(
    'top-dispersion-filters'
  )
    ? JSON.parse(localStorage.getItem('top-dispersion-filters'))
    : {},
  //! [topdispersions]

  // [watchlist]
  dashboardWatchlistSetData: null,
  dashboardWatchlistActiveSet: null,
  loadingDashboardWatchlistSet: true,
  addingDashboardTicker: false,
  //! [watchlist]

  // [relatedpairs]
  dashboardRelatedPairs: null,
  loadingDashboardRelatedPairs: false,
  //! [relatedpairs]
  // [pairs]
  dashboardPairsData: null,
  loadingDashboardPairs: true,
  dashboardPairsActiveTab: 'Favorites',
  //! [pairs]
  // [trades]
  savingDashboardTrade: false,
  updatingDashboardTrade: false,
  dashboardTradesActiveTable: 'Active',
  // ![trades]

  // [tradelogs]
  loadingDashboardTradeLogs: false,
  dashboardTradeLogsData: null,
  updatingDashboardTradeLog: false,
  //! [tradelogs]

  // [favorites]
  updatingDashboardFavorite: false,
  savingDashboardFavorite: false,
  // ![pairwatchlist]
  // [general state]
  dashboardAlertMsg: null,
  dashboardSets: null,
  dashboardPairs: null,
  dashboardDefaultSet: null,
  dashboardTableSettings: null,
  dashboardActiveTab: 'top-dispersions',
  loadingDashboardWatchlist: true,

  //! [general state]
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_WATCHLIST:
      const { pairs, sets, settings } = action.payload;
      return {
        ...state,
        loadingDashboardWatchlist: false,
        dashboardSets: sets,
        dashboardPairs: pairs,
        dashboardTableSettings: settings.tableSettings,
        dashboardDefaultSet: settings.defaultSet,
      };
    case GET_DASHBOARD_TOP_DISPERSIONS:
      return {
        ...state,
        dashboardTopDispersionsData: action.payload,
        loadingDashboardTopDispersions: false,
      };
    case GET_DASHBOARD_WATCHLIST_SET:
      return {
        ...state,
        loadingDashboardWatchlistSet: false,
        dashboardWatchlistSetData: action.payload,
      };
    case GET_DASHBOARD_RELATED_PAIRS:
      return {
        ...state,
        loadingDashboardRelatedPairs: false,
        dashboardRelatedPairs: action.payload,
      };
    case GET_DASHBOARD_TRADES:
      return {
        ...state,
        loadingDashboardTrades: false,
        dashboardTradesData: action.payload,
      };
    case GET_DASHBOARD_FAVORITES:
      return {
        ...state,
        loadingDashboardFavorites: false,
        dashboardFavoritesData: action.payload,
      };
    case GET_DASHBOARD_TRADE_LOGS:
      return {
        ...state,
        loadingDashboardTradeLogs: false,
        dashboardTradeLogsData: action.payload,
      };

    case SET_DASHBOARD_ALERT:
      return {
        ...state,
        dashboardAlertMsg: action.payload,
        dashboardTopMoversForm: action.form,
      };
    case SET_DASHBOARD_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case ADD_DASHBOARD_TICKER:
      return {
        ...state,
        addingDashboardTicker: false,
        dashboardWatchlistSetData: [
          ...state.dashboardWatchlistSetData,
          ...action.payload,
        ],
      };
    case ADD_DASHBOARD_TRADE_LOG:
      const update = state.dashboardTradesData.filter(
        (obj) => obj.id !== action.payload.id
      );
      return {
        ...state,
        dashboardTradesData: update,
      };
    case UPDATE_DASHBOARD_TRADE:
      let arr = [...state.dashboardTradesData];
      arr[arr.findIndex((log) => log.id === action.payload.id)] =
        action.payload;
      return {
        ...state,
        updatingDashboardTrade: false,
        dashboardTradesData: arr,
      };
    case UPDATE_DASHBOARD_FAVORITE:
      let key = `${action.payload.longTicker} ${action.payload.shortTicker} ${action.payload.period} ${action.payload.lookback}`;
      return {
        ...state,
        updatingDashboardFavorite: false,
        dashboardFavoritesData: {
          ...state.dashboardFavoritesData,
          [key]: action.payload,
        },
      };
    case UPDATE_DASHBOARD_TRADE_LOG:
      let logs = [...state.dashboardTradeLogsData];
      const i = logs.findIndex((log) => log.id === action.payload.id);
      logs[i] = action.payload;
      return {
        ...state,
        updatingDashboardTradeLog: false,
        dashboardTradeLogsData: logs,
      };
    case DELETE_DASHBOARD_TRADE:
      let newTrades = [
        ...state.dashboardTradesData.filter((obj) => obj.id !== action.payload),
      ];
      return {
        ...state,
        dashboardTradesData: newTrades,
      };
    case DELETE_DASHBOARD_FAVORITE:
      let newFavorites = { ...state.dashboardFavoritesData };
      Object.entries(newFavorites).forEach(([key, value]) => {
        if (value.id === action.payload) {
          delete newFavorites[key];
        }
      });
      return {
        ...state,
        dashboardFavoritesData: newFavorites,
      };
    case DELETE_DASHBOARD_TRADE_LOG:
      let newTradeLogs = [
        ...state.dashboardTradeLogsData.filter(
          (obj) => obj.id !== action.payload
        ),
      ];

      return {
        ...state,
        dashboardTradeLogsData: newTradeLogs,
      };
    default:
      return state;
  }
};
export default reducer;
