import React, { useEffect } from 'react';
import Swal from 'sweetalert2';

const ErrorFallbackPopup = () => {
  useEffect(() => {
    Swal.fire({
      title: 'Ooops!',
      text: 'Somthing went wrong. Please try again',
      allowOutsideClick: false,
      icon: 'error',
      confirmButtonText: 'Reload',
    }).then(() => {
      window.location.reload();
    });
  }, []);

  return <></>;
};
export default ErrorFallbackPopup;
