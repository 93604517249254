import {
  LOADING_RELATIVE_VALUE_CHARTS,
  GET_RELATIVE_VALUE_CHARTS,
  LOADING_RELATIVE_VALUE_BACKTEST,
  GET_RELATIVE_VALUE_OPTIONS_OPTIMIZER,
  LOADING_RELATIVE_VALUE_OPTIONS_OPTIMIZER,
  GET_RELATIVE_VALUE_BACKTEST,
  SET_RELATIVE_VALUE_ALERT,
  SET_RELATIVE_VALUE_FORM,
  SET_RELATIVE_VALUE_STATE,
} from '../actions/types';
import {
  getInverse,
  getClosestAproximateOptionsMatch,
  findOptionLessThan,
  findOption,
} from '../components/relativeValueComponents/OptionsOptimizer/utils/optionSubroutines';

const initialState = {
  relativeValueActiveTab: 'backtest',
  //charts
  chartData: null,
  loadingCharts: undefined,

  //backtest
  backtestData: null,
  loadingBacktest: false,
  backtestForm: {
    df: null,
    period: 5,
    lookback: 504,
    longTicker: 'SPY',
    shortTicker: 'HYG',
    filterCriteria: { targetLow: -2, targetHigh: -1 },
    greater: true,
    exitThreshold: 0,
    overlap: false,
    capital: 100000,
    beta: null,
  },

  //options optimizer
  optionsOptimizerData: null,
  loadingOptionsOptimizer: undefined,
  optionsOptimizerForm: {
    longTicker: 'SPY',
    shortTicker: 'HYG',
    pullOptionsChainFor: 'both',
    avgTimeToReversion: null,
  },
  optionsOptimizerState: {
    wholeContracts: 100,
    activeSpreadTile: 'Singles',
    activeTab: 'Setup',
    walkthroughActiveTab: 'Entry',
    activeSetup: 'Optimal Setup',
  },

  optionsOptimizerOptions: {
    longLegs: null,
    shortLegs: null,
    longInverseLegs: null,
    shortInverseLegs: null,
    shortTickerError: false,
    longTickerError: false,
  },
  //general
  mainForm: {
    df: null,
    longTicker: 'SPY',
    shortTicker: 'HYG',
    period: 5,
    lookback: 504,
  },
  alertMsg: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RELATIVE_VALUE_CHARTS:
      //here we should also update backtests form with the new form info so when they load backtest the data will be the same
      return {
        ...state,
        chartData: { ...action.payload, form: action.form },
        loadingCharts: false,
      };
    case GET_RELATIVE_VALUE_BACKTEST:
      return {
        ...state,
        backtestData: { ...action.payload, form: action.form },
        loadingBacktest: false,
      };
    case GET_RELATIVE_VALUE_OPTIONS_OPTIMIZER:
      const [longTicker, shortTicker] = [
        action.payload.longTicker,
        action.payload.shortTicker,
      ];
      //if there is not yet any data what we need to do is chose the third expiry date and chose and option that has a 50 delta call for long leg and a -25 delta put for short leg
      if (
        (!state.optionsOptimizerData && action.payload.isCrypto === false) ||
        (action.form.pullOptionsChainFor === 'both' &&
          action.payload.isCrypto === false)
      ) {
        const longExpirations = Object.keys(
          action.payload.optionsChain[longTicker]
        );
        const shortExpirations = Object.keys(
          action.payload.optionsChain[shortTicker]
        );
        let longTickerExpiration, shortTickerExpiration;
        //now we need to find the expiratiion closest to average time to reversion if the average time to reversion is known
        if (action.form.avgTimeToReversion !== null) {
          var ms =
            new Date().getTime() + 86400000 * action.form.avgTimeToReversion;
          const wantedExpiration = new Date(ms);
          longTickerExpiration = longExpirations.reduce((prev, curr) => {
            return Math.abs(new Date(prev) - wantedExpiration) <
              Math.abs(new Date(curr) - wantedExpiration)
              ? prev
              : curr;
          });

          shortTickerExpiration = shortExpirations.reduce((prev, curr) => {
            return Math.abs(new Date(prev) - wantedExpiration) <
              Math.abs(new Date(curr) - wantedExpiration)
              ? prev
              : curr;
          });
        } else {
          longTickerExpiration = longExpirations[2];
          shortTickerExpiration = shortExpirations[2];
        }
        //find the call with the closest delta to 0.50
        const long = findOption(
          action.payload.optionsChain[longTicker][longTickerExpiration]['C'],
          0.5
        );
        //find the short side with the closest delta to 0.25
        const short = findOptionLessThan(
          action.payload.optionsChain[shortTicker][shortTickerExpiration]['C'],
          0.3
        );

        //set the form with the data
        let options = {
          ...state.optionsOptimizerOptions,
          longLegs: { 0: long },
          shortLegs: { 0: { ...short, qty: -1 } },
          longInverseLegs: {
            0: findOptionLessThan(
              action.payload.optionsChain[longTicker][longTickerExpiration][
              'P'
              ],
              -0.3
            ),
          },
          shortInverseLegs: getInverse(
            { 0: long },
            action.payload.optionsChain[shortTicker]
          ),
        };
        return {
          ...state,
          optionsOptimizerData: action.payload,
          optionsOptimizerOptions: options,
          optionsOptimizerForm: action.form,
          loadingOptionsOptimizer: false,
        };
      }
      //we also need to check if we only pulled data for one ticker. If so add the new options chain to our current one
      else if (action.payload.isCrypto === false) {
        // if there is already data then we just need to find the cloest approximate match
        let longLegs = state.optionsOptimizerOptions.longLegs;
        let shortLegs = state.optionsOptimizerOptions.shortLegs;
        //if data was pulled for the ticker (somtimes we just pull for one leg)
        if (longTicker in action.payload.optionsChain) {
          longLegs = getClosestAproximateOptionsMatch(
            state.optionsOptimizerOptions.longLegs,
            action.payload.optionsChain[longTicker]
          );
        } //if it wasnt in there then that means we only pulled the options chain for one ticker
        else {
          action.payload.optionsChain[longTicker] =
            state.optionsOptimizerData.optionsChain[longTicker];
        }
        if (shortTicker in action.payload.optionsChain) {
          shortLegs = getClosestAproximateOptionsMatch(
            shortLegs,
            action.payload.optionsChain[longTicker]
          );
        } else {
          action.payload.optionsChain[shortTicker] =
            state.optionsOptimizerData.optionsChain[shortTicker];
        }

        const options = {
          ...state.optionsOptimizerOptions,
          longLegs: longLegs,
          shortLegs: shortLegs,
          longInverseLegs: getInverse(
            shortLegs,
            longTicker in action.payload.optionsChain
              ? action.payload.optionsChain[longTicker]
              : state.optionsOptimizerData.optionsChain[longTicker]
          ),
          shortInverseLegs: getInverse(
            longLegs,
            shortTicker in action.payload.optionsChain
              ? action.payload.optionsChain[shortTicker]
              : state.optionsOptimizerData.optionsChain[shortTicker]
          ),
        };
        return {
          ...state,
          optionsOptimizerData: action.payload,
          optionsOptimizerForm: action.form,
          loadingOptionsOptimizer: false,
          optionsOptimizerOptions: options,
        };
      }
      break;
    case LOADING_RELATIVE_VALUE_CHARTS:
      return {
        ...state,
        loadingCharts: true,
      };
    case LOADING_RELATIVE_VALUE_BACKTEST:
      return {
        ...state,
        loadingBacktest: true,
      };
    case LOADING_RELATIVE_VALUE_OPTIONS_OPTIMIZER:
      return {
        ...state,
        loadingOptionsOptimizer: true,
      };

    case SET_RELATIVE_VALUE_ALERT:
      return {
        ...state,
        loadingPairInfo: false,
        loadingCharts: false,
        loadingBacktest: false,
        loadingOptionsOptimizer: false,
        loadingRelativeValue: false,
        alertMsg: action.payload,
      };
    //This next function is used to update the form. action contains the name of the form. This function is used for all form updates
    case SET_RELATIVE_VALUE_FORM:
      if (action.payload === 'optionsOptimizerOptions') {
        return {
          ...state,
          [action.payload]: action.form,
        };
      } else {
        return {
          ...state,
          //lets also update the tickers on all the other forms
          mainForm: {
            ...state.mainForm,
            longTicker: action.form.longTicker,
            shortTicker: action.form.shortTicker,
            period: action.form.period
              ? action.form.period
              : state.mainForm.period,
            lookback:
              action.payload === 'optionsOptimizerForm'
                ? state.mainForm.lookback
                : action.form.lookback,
          },
          backtestForm: {
            ...state.backtestForm,
            longTicker: action.form.longTicker,
            shortTicker: action.form.shortTicker,
            period: action.form.period
              ? action.form.period
              : state.mainForm.period,

            lookback:
              action.payload === 'optionsOptimizerForm'
                ? state.mainForm.lookback
                : action.form.lookback,
          },
          optionsOptimizerForm: {
            ...state.optionsOptimizerForm,
            longTicker: action.form.longTicker,
            shortTicker: action.form.shortTicker,
          },
          [action.payload]: action.form,
        };
      }
    case SET_RELATIVE_VALUE_STATE:
      return {
        ...state,
        [action.key]: action.payload,
      };
    default:
      return state;
  }
};
export default reducer;
