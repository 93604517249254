import { combineReducers } from 'redux';
import authReducer from './authReducer';
import relativeValueReducer from './relativeValueReducer';
import dashboardReducer from './dashboardReducer';
import pairInfoReducer from './pairInfoReducer';
import matrixReducer from './matrixReducer';

export default combineReducers({
  auth: authReducer,
  relativeValue: relativeValueReducer,
  dashboard: dashboardReducer,
  pairInfo: pairInfoReducer,
  matrix: matrixReducer,
});
