import React, { Suspense, lazy, useEffect, useRef } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import PrivateRoute from './routing/PrivateRoute';
import PublicRoute from './routing/PublicRoute';
import { connect } from 'react-redux';
import Spinner from '../app/shared/Spinner';
import { loadUser } from './actions/authActions';
import axios from 'axios';
import UpdateVersionPopup from './popups/UpdateVersionPopup';

const NotFoundPage = lazy(() => import('./pages/404'));
const Home = lazy(() => import('./pages/Home'));
const Entry = lazy(() => import('./pages/Entry'));
const RelativeValue = lazy(() => import('./pages/RelativeValue'));
const Login = lazy(() => import('./auth/Login'));
const Register = lazy(() => import('./auth/Register'));
const Settings = lazy(() => import('./pages/Settings'));
const Recover = lazy(() => import('./auth/Recover'));
const Reset = lazy(() => import('./auth/Reset'));
const Verify = lazy(() => import('./auth/Verify'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const TermsOfUse = lazy(() => import('./pages/TermsOfUse'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const Matrix = lazy(() => import('./pages/Matrix'));
const Earnings = lazy(() => import('./pages/Earnings'));

export const PUBLIC_ROUTES = new Set([
  '/',
  '/reset',
  '/recover',
  '/home',
  '/login',
  '/register',
  '/verify',
  '/privacy-policy',
  '/terms-of-use',
  '/404',
]);
const AppRoutes = ({ auth, loadUser }) => {
  const updateVersionPopupRef = useRef();
  //this is the second interceptor we are using. This first on is in index and those events get called first.
  //We have this one only because we need to push history and to push history it has to be in a component
  axios.interceptors.response.use(
    (res) => {
      if (res.response?.status !== 403) return res;
    },
    (error) => {
      if (error.response?.status === 426) {
        updateVersionPopupRef.current.open();
      }
      return Promise.reject(error);
    }
  );
  useEffect(() => {
    if (!auth.isAuthenticated) {
      loadUser();
    }
  }, [loadUser, auth.isAuthenticated]);

  if (
    !auth.isAuthenticated &&
    auth.loading &&
    !PUBLIC_ROUTES.has(window.location.pathname)
  ) {
    return <Spinner />;
  } else {
    return (
      <Suspense fallback={<Spinner />}>
        <UpdateVersionPopup ref={updateVersionPopupRef} />

        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/home">
            <Redirect to="/login" />
          </Route>
          <PublicRoute exact path="/login" component={Login} />
          <PublicRoute exact path="/reset" component={Reset} />
          <PublicRoute exact path="/register" component={Register} />
          <PublicRoute exact path="/verify" component={Verify} />
          <PublicRoute exact path="/404" component={NotFoundPage} />
          <PublicRoute exact path="/recover" component={Recover} />
          <PublicRoute exact path="/terms-of-use" component={TermsOfUse} />
          <PublicRoute exact path="/privacy-policy" component={PrivacyPolicy} />

          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/earnings" component={Earnings} />
          <PrivateRoute exact path="/matrix" component={Matrix} />
          <PrivateRoute
            path={process.env.REACT_APP_RELATIVE_VALUE_URL}
            component={RelativeValue}
          />
          <PrivateRoute exact path={'/entry'} component={Entry} />
          <PrivateRoute path="/settings" component={Settings} />

          <Redirect to="/404" />
        </Switch>
      </Suspense>
    );
  }
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { loadUser })(AppRoutes);
