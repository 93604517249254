import React from 'react';

const Spinner = ({ wrapperClass = 'spinner-wrapper' }) => {
  return (
    <div>
      <div className={wrapperClass}>
        <div className="donut"></div>
      </div>
    </div>
  );
};

export default Spinner;
