//Analyze
export const LOADING_RELATIVE_VALUE_BACKTEST =
  'LOADING_RELATIVE_VALUE_BACKTEST';
export const LOADING_RELATIVE_VALUE_OPTIONS_OPTIMIZER =
  'LOADING_RELATIVE_VALUE_OPTIONS_OPTIMIZER';
export const LOADING_RELATIVE_VALUE_CHARTS = 'LOADING_RELATIVE_VALUE_CHARTS';
export const GET_RELATIVE_VALUE_CHARTS = 'GET_RELATIVE_VALUE_CHARTS';
export const GET_RELATIVE_VALUE_BACKTEST = 'GET_RELATIVE_VALUE_BACKTEST';
export const GET_RELATIVE_VALUE_OPTIONS_OPTIMIZER =
  'GET_RELATIVE_VALUE_OPTIONS_OPTIMIZER';
export const SET_RELATIVE_VALUE_ALERT = 'SET_RELATIVE_VALUE_ALERT';
export const SET_RELATIVE_VALUE_FORM = 'SET_RELATIVE_VALUE_FORM';
export const SET_RELATIVE_VALUE_STATE = 'SET_RELATIVE_VALUE_STATE';

//Matrix
export const LOADING_MATRIX = 'LOADING_MATRIX';
export const GET_MATRIX = 'GET_MATRIX';
export const SET_MATRIX_ALERT = 'SET_MATRIX_ALERT';

//Overveiw
export const INIT_OVERVIEW = 'INIT_OVERVIEW';
export const ADD_OVERVIEW_ROW = 'ADD_OVERVIEW_ROW';
export const ADDING_OVERVIEW_ROW = 'ADDING_OVERVIEW_ROW';
export const DELETE_OVERVIEW_ROW = 'DELETE_OVERVIEW_ROW';
export const LOADING_OVERVIEW = 'LOADING_OVERVIEW';
export const SET_OVERVIEW_TICKERS = 'SET_OVERVIEW_CURRENT_TICKERS';
export const CHANGE_OVERVIEW_CURRENT_TICKERS =
  'CHANGE_OVERVIEW_CURRENT_TICKERS';
export const SET_OVERVIEW_ALERT = 'SET_OVERVIEW_ALERT';

//Auth
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const USER_LOADED = 'USER_LOADED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const PROFILE_CHANGED = 'EMAIL_CHANGED';
export const LOGOUT = 'LOGOUT';
export const SET_AUTH_ALERT = 'SET_AUTH_ALERT';
export const LOADING_LOGIN = 'LOADING_LOGIN';
export const LOADING_REGISTER = 'LOADING_REGISTER';
export const LOADING_USER = 'LOADING_USER';
export const LOADING_SEND_VERIFICATION_EMAIL =
  'LOADING_SEND_VERIFICATION_EMAIL';
export const SEND_VERIFICATION_EMAIL_SUCCESS =
  'SEND_VERIFICATION_EMAIL_SUCCESS';
export const SET_AUTH_MESSAGE = 'SET_AUTH_MESSAGE';
export const CLEAR_AUTH_MESSAGE = 'CLEAR_AUTH_MESSAGE';
export const CLEAR_AUTH_MESSAGES = 'CLEAR_AUTH_MESSAGES';
export const LOADING_VERIFICATION = 'LOADING_VERIFICATION';
export const VERIFICATION_SUCCESS = 'VERIFICATION_SUCCESS';
export const VERIFICATION_FAILURE = 'VERIFICATION_FAILURE';
export const LOADING_TOKEN_VALIDATION = 'LOADING_TOKEN_VALIDATION';
export const TOKEN_VALIDATION_COMPLETE = 'TOKEN_VALIDATION_COMPLETE';
export const TOKEN_VALIDATION_FAILED = 'TOKEN_VALIDATION_FAILED';
export const LOADING_SEND_EMAIL = 'LOADING_SEND_EMAIL';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const SET_AUTH_STATE = 'SET_AUTH_STATE';
export const LOADING_PROFILE_CHANGE = 'LOADING_EMAIL_CHANGE';
export const CLEAR_PROFILE_ALERT = 'CLEAR_PROFILE_ALERT';
export const UPDATE_DEFAULT_SETTINGS = 'UPDATE_DEFAULT_SETTINGS';

//Dashboard
export const GET_DASHBOARD_TOP_DISPERSIONS = 'GET_DASHBOARD_TOP_DISPERSIONS';
export const SET_DASHBOARD_STATE = 'SET_DASHBOARD_STATE';
export const SET_DASHBOARD_ALERT = 'SET_DASHBOARD_ALERT';
export const GET_DASHBOARD_WATCHLIST = 'GET_DASHBOARD_WATCHLIST';
export const GET_DASHBOARD_WATCHLIST_SET = 'GET_DASHBOARD_WATCHLIST_SET';
export const ADD_DASHBOARD_TICKER = 'ADD_DASHBOARD_TICKER';
export const GET_DASHBOARD_RELATED_PAIRS = 'GET_DASHBOARD_RELATED_PAIRS';
export const GET_DASHBOARD_TRADES = 'GET_DASHBOARD_TRADES';
export const DELETE_DASHBOARD_TRADE = 'DELETE_DASHBOARD_TRADE';
export const UPDATE_DASHBOARD_TRADE = 'UPDATE_DASHBOARD_TRADE';

export const GET_DASHBOARD_FAVORITES = 'GET_DASHBOARD_FAVORITES';
export const DELETE_DASHBOARD_FAVORITE = 'DELETE_DASHBOARD_FAVORITE';
export const UPDATE_DASHBOARD_FAVORITE = 'UPDATE_DASHBOARD_FAVORITE';
export const GET_DASHBOARD_TRADE_LOGS = 'GET_DASHBOARD_TRADE_LOGS';
export const ADD_DASHBOARD_TRADE_LOG = 'ADD_DASHBOARD_TRADE_LOG';
export const DELETE_DASHBOARD_TRADE_LOG = 'DELETE_DASHBOARD_TRADE_LOG';
export const UPDATE_DASHBOARD_TRADE_LOG = 'UPDATE_DASHBOARD_TRADE_LOG';

//Pair info
export const GET_PAIR_INFO_STATS = 'GET_PAIR_INFO_STATS';
export const SET_PAIR_INFO_STATE = 'SET_PAIR_INFO_STATE';
export const GET_PAIR_INFO_NEWS = 'SET_PAIR_INFO_NEWS';
