import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './app/App';
import axios from 'axios';
import { PUBLIC_ROUTES } from './app/AppRoutes';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

axios.defaults.withCredentials = true;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    if (
      error.response?.status === 401 &&
      !PUBLIC_ROUTES.has(window.location.pathname)
    ) {
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

Sentry.init({
  dsn: 'https://3c8f7ed3c2854e12b038dbec0e0e5909@o4504741873451008.ingest.sentry.io/4504741875089408',
  integrations: [new BrowserTracing(), new Sentry.Replay()],

  tracesSampleRate: process.env.REACT_APP_SENTRY_TRACE_RATE,
  replaysOnErrorSampleRate: process.env.REACT_APP_SENTRY_TRACE_RATE,

  release: process.env.REACT_APP_VERSION,
  environment: process.env.REACT_APP_ENVIROMENT,

  enabled: process.env.REACT_APP_SENTRY_ENABLED === 'true' ? true : false,
  autoSessionTracking: true,
});

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
