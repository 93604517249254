import React from 'react';
import ErrorFallbackPopup from '../popups/ErrorFallbackPopup';
import { ErrorBoundary } from 'react-error-boundary';
import * as Sentry from '@sentry/react';

const withErrorBoundary = (
  WrappedComponent,
  FallbackComponent = ErrorFallbackPopup
) => {
  const logError = (error, info) => {
    try {
      Sentry.captureException(error, { extra: info });
    } catch {}
  };
  return (props) => {
    return (
      <ErrorBoundary FallbackComponent={FallbackComponent} onError={logError}>
        <WrappedComponent {...props} />
      </ErrorBoundary>
    );
  };
};

export default withErrorBoundary;
