import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { loadUser } from '../actions/authActions';
import withErrorBoundary from '../hocs/withErrorBoundary';

const PrivateRoute = ({
  data: { isAuthenticated },
  component: Component,
  loadUser,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          withErrorBoundary(Component)(props)
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  data: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  data: state.auth,
});

export default connect(mapStateToProps, {
  loadUser,
})(PrivateRoute);
