import React, { forwardRef, useImperativeHandle } from 'react';

//this needs to be imported or else the title on the sweet alert doesnt appear for some reason
//eslint-disable-next-line
import SweetAlert from 'sweetalert2-react';
import updateVersionIcon from '../../assets/images/popup-modals/update-version.png';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import $ from 'jquery';
import { useDispatch } from 'react-redux';
import { logout } from '../actions/authActions';
const MySwal = withReactContent(Swal);

const UpdateVersionPopup = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  useImperativeHandle(
    ref,
    () => ({
      open() {
        MySwal.fire({
          title: <strong>Update Available!</strong>,
          html: <PopupConent />,
          imageUrl: updateVersionIcon,
          showConfirmButton: false,
          allowOutsideClick: false,
        });
        $(document).on('click', '#update', () => {
          $('#update').html(
            '<div class="spinner-border spinner-border-sm text-light" role="status"><span class="sr-only"></span></div>'
          );
          dispatch(logout()).then(() => {
            MySwal.close();
          });
        });
      },
    }),
    [dispatch]
  );

  return <></>;
});

export default UpdateVersionPopup;

const PopupConent = () => {
  return (
    <div className="col-md-12">
      <h4>Update requires you to re-sign in to your QuantStop account</h4>
      <div className="row mt-4 justify-content-center">
        <div className="col-sm-6 mb-3">
          <button className="btn btn-block btn-success" id="update">
            Update
          </button>
        </div>
      </div>
    </div>
  );
};
