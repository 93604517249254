import {
  GET_PAIR_INFO_STATS,
  SET_PAIR_INFO_STATE,
  GET_PAIR_INFO_NEWS,
} from '../actions/types';

const initialState = {
  // [stats state]
  pairInfoStats: null,
  loadingPairInfoStats: true,
  //! [stats state]

  // [news state]
  pairInfoNews: null,
  loadingPairInfoNews: true,
  //! [news state]

  // [general state]
  pairInfoAlertMsg: null,
  //! [general state]
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PAIR_INFO_STATS:
      return {
        ...state,
        loadingPairInfoStats: false,
        pairInfoStats: action.payload,
      };
    case GET_PAIR_INFO_NEWS:
      return {
        ...state,
        loadingPairInfoNews: false,
        pairInfoNews: action.payload,
      };
    case SET_PAIR_INFO_STATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export default reducer;
