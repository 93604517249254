/**
 * This function calculates the hedge ratio based off long and short ticker legs. The
 * @param {Object} longLegs are the options associated with the long ticker
 * @param {Object} shortLegs are the options associated with the short ticker
 * @param {number} beta is the beta of the pair
 *  */
export const getOptionHedgeRatio = (longLegs, shortLegs, beta) => {
  longLegs = Object.values(longLegs);
  shortLegs = Object.values(shortLegs);

  const longTickerDelta = longLegs.reduce(
    (acc, leg) => acc + leg.delta * leg.qty,
    0
  );
  const shortTickerDelta = shortLegs.reduce(
    (acc, leg) => acc + leg.delta * leg.qty,
    0
  );
  //now calculate ratio
  const ratio = !shortLegs.map((leg) => leg.qty === 0).includes(false)
    ? 0
    : parseFloat(
      Math.abs(
        (longTickerDelta * longLegs[0].underlyingPrice) /
        (shortTickerDelta * shortLegs[0].underlyingPrice * (1 / beta))
      )
    );
  //add 0 to implicitly cast to number
  return parseFloat(ratio.toFixed(2));
};

/**
 * This function applies a certain multiple e.g. a hedge ratio to the quantity of a option for all options in a object
 * @param {Object} options are the options that you are apply the hedge ratio to. This should be the short tickers options
 * @param {Number} multiple is the multiplier to apply
 *  */
export const applyMultiple = (options, multiple) => {
  return Object.fromEntries(
    Object.entries(options).map(([key, value]) => [
      key,
      { ...value, qty: parseFloat((value.qty * multiple).toFixed(2)) },
    ])
  );
};

/**
 * This function calculates the inverse for a given ticker
 * @param {Object} options are the options that you want to calculate the inverse of
 * @param {Object} optionsChain is the options chain of the the given ticker
 *  */
export const getInverse = (options, optionsChain) => {
  return Object.fromEntries(
    Object.entries(options).map(([key, obj]) => [
      key,
      {
        ...optionsChain[
          optionsChain[obj.expiration] !== //if the current expiration exists inn this options chain we can use that else we have to find the closest expiration
            undefined
            ? obj.expiration
            : Object.keys(optionsChain).reduce((prev, curr) => {
              return Math.abs(new Date(curr) - new Date(obj.expiration)) <
                Math.abs(new Date(prev) - new Date(obj.expiration))
                ? curr
                : prev;
            })
        ][obj.optionType === 'P' ? 'C' : 'P'].reduce((prev, curr) => {
          //now find the closest delta for the inverse option type
          return Math.abs(curr.delta - obj.delta * -1) <
            Math.abs(prev.delta - obj.delta * -1)
            ? curr
            : prev;
        }),
        qty: obj.qty,
      },
    ])
  );
};
/**
 * This function finds and return the cloest aproximate match based off expiration and delta for an object full of objects
 * @param {Object} options is an object of options that you wish to replace the options with
 * @param {Object} optionsChain this is the options chain for the underlying ticker
 */

export const getClosestAproximateOptionsMatch = (options, optionsChain) => {
  return Object.fromEntries(
    Object.entries(options).map(([key, obj]) => [
      key,
      //now find the closest expiration
      {
        ...optionsChain[
          optionsChain[obj.expiration] !== //if the current expiration exists inn this options chain we can use that else we have to find the closest expiration
            undefined
            ? obj.expiration
            : Object.keys(optionsChain).reduce((prev, curr) => {
              return Math.abs(new Date(curr) - new Date(obj.expiration)) <
                Math.abs(new Date(prev) - new Date(obj.expiration))
                ? curr
                : prev;
            })
        ][obj.optionType].reduce((prev, current) =>
          Math.abs(current.delta - obj.delta) < Math.abs(prev.delta - obj.delta)
            ? current
            : prev
        ),
        qty: obj.qty,
      },
    ])
  );
};
/**
 * This function calculates the current setup which essentially applies a hedge ratio to the short tickers position aswell as applies any contract
 *  multiples if needed.
 * @param {Object} options are the options we are calculating the current setup for. This should include keys of "shortLegs" and "longLegs"
 * @param {Number} beta is the beta of the pair
 * @param {Number} multiple is the contract multiple of the pair if you want to multiply the quantity of each option by a certain amount
 *  */
export const getCurrentSetup = (options, beta, multiple) => {
  // first we need to get the current setup
  //first find hedge ratio of current setup
  const hedgeRatio = getOptionHedgeRatio(
    options.longLegs,
    options.shortLegs,
    beta
  );
  return {
    longTickerLegs:
      multiple === 1
        ? options.longLegs
        : applyMultiple(options.longLegs, multiple),
    shortTickerLegs: applyMultiple(options.shortLegs, hedgeRatio * multiple),
    ratio: hedgeRatio,
  };
};

/**
 * This function calculates the options inverse setup of a given pair of options
 * @param {Object} options are the options we are trying to mock the inverse of
 * @param {Number} beta is the beta of the pair
 * @param {Number} multiple is the contract multiple of the pair if you want to multiply the quantity of each option by a certain amount
 * @param {Object} optionsChain is the options chain for both ticker where <longTicker> key leads to the long tickers options chain and <shortTicker>
 *  key leads to the short tickers options chain
 *  */
export const getInverseSetup = (
  options,
  beta,
  multiple,
  optionsChain,
  longTicker,
  shortTicker
) => {
  //first inverse the legs
  const longTickerInverseLegs = getInverse(
    options.shortLegs,
    optionsChain[longTicker]
  );
  const shortTickerInverseLegs = getInverse(
    options.longLegs,
    optionsChain[shortTicker]
  );
  //now that we have the legs we can calcualte the hedge ratio
  const inverseHedgeRatio = getOptionHedgeRatio(
    longTickerInverseLegs,
    shortTickerInverseLegs,
    beta
  );
  //now we can get the full setup
  return {
    longTickerLegs:
      multiple === 1
        ? longTickerInverseLegs
        : applyMultiple(longTickerInverseLegs, multiple),
    shortTickerLegs: applyMultiple(
      shortTickerInverseLegs,
      inverseHedgeRatio * multiple
    ),
    ratio: inverseHedgeRatio,
  };
};

/**
 * Get the total cost of the setup
 * @param {Object} setup is the object that is returned from 'getCurrentSetup' or getInverseSetup'
 */
export const getTotalCost = (setup) => {
  return (
    Object.values(setup.longTickerLegs).reduce((sum, value) => {
      return sum + value.mid * value.qty;
    }, 0) +
    Object.values(setup.shortTickerLegs).reduce((sum, value) => {
      return sum + value.mid * value.qty;
    }, 0)
  );
};
/**
 * Finds the closest options given an options chain and a delta
 * @param {Object} chain is the options chain where the expiration date and option type is known
 * @param {Number} delta is the desired delta
 *  */
export const findOption = (chain, delta) => {
  return chain.reduce((prev, cur) =>
    Math.abs(cur.delta - delta) < Math.abs(prev.delta - delta) ? cur : prev
  );
};

/**
 * Finds the closest options given an options chain and a delta and ensures that that deleta is less than or equal to the inputed delta
 * @param {Array[Object]} chain is the options chain where the expiration date and option type is known
 * @param {Number} delta is the desired delta
 *  */
export const findOptionLessThan = (chain, delta) => {
  const min = Math.abs(delta);
  const data = chain.sort((a, b) => a.delta - b.delta)
  for (let i = 1; i < data.length; i++) {
    if (Math.abs(data[i].delta) >= min) {
      return data[i - 1]
    }
  }
  return data[0]
};

/**
 * Finds the closest options given an options chain and a delta and ensures that that deleta is greater than or equal to the inputed delta
 * @param {Array[Object]} chain is the options chain where the expiration date and option type is known
 * @param {Number} delta is the desired delta
 *  */
export const findOptionGreaterThan = (chain, delta) => {
  const max = Math.abs(delta);
  const data = chain.sort((a, b) => a.delta - b.delta).reverse();
  for (let i = 1; i < data.length; i++) {
    if (Math.abs(data[i].delta) <= max) {
      return data[i - 1]
    }
  }
  return data[0]
};
