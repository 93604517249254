import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import withErrorBoundary from '../hocs/withErrorBoundary';
import { useSelector } from 'react-redux';

const PublicRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useSelector((state) => state.auth);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated &&
        !['/privacy-policy', '/terms-of-use'].includes(
          window.location.pathname
        ) ? (
          <Redirect to={process.env.REACT_APP_MAIN_REDIRECT_URL} />
        ) : (
          withErrorBoundary(Component)(props)
        )
      }
    />
  );
};

export default PublicRoute;
