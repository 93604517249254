import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { logout } from '../actions/authActions';
import PropTypes from 'prop-types';

const Navbar = ({ logout, data: { user } }) => {
  const {
    relativeValue: { relativeValueActiveTab },
    dashboard: { dashboardActiveTab },
  } = useSelector((state) => state);
  const onLogout = () => {
    logout();
  };
  const history = useHistory();
  const isPathActive = (path) => {
    return history.location.pathname.startsWith(path);
  };
  if (!user) {
    return <></>;
  } else
    return (
      <nav className="navbar p-0 fixed-top d-flex flex-row">
        <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
          <Link className="brand-logo-mini align-self-center" to="/">
            <h2 className="logo">Q</h2>
          </Link>
          <Link className="brand-logo align-self-center" to="/">
            <h2 className="logo">QuantStop</h2>
          </Link>
          <Dropdown
            alignRight
            className="nav-item align-self-center nav-tabs-mini"
          >
            <Dropdown.Toggle
              as="a"
              className="nav-link cursor-pointer no-caret"
            >
              <span className="align-self-center text-muted mdi mdi-menu"></span>
            </Dropdown.Toggle>

            <Dropdown.Menu className="navbar-dropdown preview-list navbar-profile-dropdown-menu">
              <Dropdown.Item
                onClick={() => history.push('/dashboard/' + dashboardActiveTab)}
              >
                <div className="nav-link">
                  <span className="menu-icon">
                    <i className="mdi mdi-chart-bar text-info"></i>
                  </span>
                  <span className="menu-title ml-2">
                    <span>Dashboard</span>
                  </span>
                </div>
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  history.push(
                    process.env.REACT_APP_RELATIVE_VALUE_URL +
                    '/' +
                    relativeValueActiveTab
                  )
                }
              >
                <div className="nav-link">
                  <span className="menu-icon">
                    <i className="mdi mdi-magnify text-primary"></i>
                  </span>
                  <span className="menu-title ml-2">
                    <span>{process.env.REACT_APP_RELATIVE_VALUE_NAME}</span>
                  </span>
                </div>
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  history.push(
                    '/matrix'
                  )
                }
              >
                <div className="nav-link">
                  <span className="menu-icon">
                    <i className="mdi mdi-blur text-primary"></i>
                  </span>
                  <span className="menu-title ml-2">
                    <span>Matrix</span>
                  </span>
                </div>
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  history.push(
                    '/earnings'
                  )
                }
              >
                <div className="nav-link">
                  <span className="menu-icon">
                    <i className="mdi mdi-calendar text-primary"></i>
                  </span>
                  <span className="menu-title ml-2">
                    <span>Earnings</span>
                  </span>
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <ul className="navbar-nav w-100 d-flex justify-content-center">
            <Link
              as={'li'}
              className={
                isPathActive('/dashboard')
                  ? 'nav-item nav-tab nav-link active'
                  : 'nav-item nav-tab nav-link'
              }
              to={'/dashboard/' + dashboardActiveTab}
            >
              <span className="nav-link">
                <span className="menu-title">
                  <span>Dashboard</span>
                </span>
              </span>
            </Link>
            <Link
              as={'li'}
              className={
                isPathActive(process.env.REACT_APP_RELATIVE_VALUE_URL)
                  ? 'nav-item nav-tab nav-link active'
                  : 'nav-item nav-tab nav-link'
              }
              to={
                process.env.REACT_APP_RELATIVE_VALUE_URL +
                '/' +
                relativeValueActiveTab
              }
            >
              <span className="nav-link">
                <span className="menu-title">
                  <span>{process.env.REACT_APP_RELATIVE_VALUE_NAME}</span>
                </span>
              </span>
            </Link>
            <Link
              as={'li'}
              className={
                isPathActive('/matrix')
                  ? 'nav-item nav-tab nav-link active'
                  : 'nav-item nav-tab nav-link'
              }
              to={'/matrix'}
            >
              <span className="nav-link">
                <span className="menu-title">
                  <span>Matrix</span>
                </span>
              </span>
            </Link>
            <Link
              as={'li'}
              className={
                isPathActive('/earnings')
                  ? 'nav-item nav-tab nav-link active'
                  : 'nav-item nav-tab nav-link'
              }
              to={'/earnings'}
            >
              <span className="nav-link">
                <span className="menu-title">
                  <span>Earnings</span>
                </span>
              </span>
            </Link>
          </ul>

          <ul className="navbar-nav navbar-nav-right align-self-center">
            <Dropdown alignRight as="li" className="nav-item border-right">
              <Dropdown.Toggle
                as="a"
                className="nav-link cursor-pointer no-caret"
              >
                <div className="navbar-profile mr-1">
                  <i className="mdi mdi-link-variant"></i>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu className="navbar-dropdown preview-list navbar-profile-dropdown-menu">
                <h6 className="p-3 mb-0">
                  <span>Resources</span>
                </h6>
                <Dropdown.Divider />
                <Dropdown.Item
                  as={'a'}
                  href="https://discord.gg/uqQgYhBGxq"
                  target="_blank"
                  className="preview-item"
                  name="discord"
                >
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-info rounded-circle">
                      <i className="mdi mdi-discord"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject mb-1">
                      <span>Discord</span>
                    </p>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item
                  as={'a'}
                  href="https://docs.quantstop.io"
                  target="_blank"
                  className="preview-item"
                  name="documentation"
                >
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-card rounded-circle">
                      <i className="mdi mdi-book-open-variant"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject mb-1">
                      <span>Documentation</span>
                    </p>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {user && (
              <Dropdown alignRight as="li" className="nav-item">
                <Dropdown.Toggle
                  as="a"
                  className="nav-link cursor-pointer no-caret"
                >
                  {user && (
                    <div className="navbar-profile">
                      <span className="rounded-circle bg-card-light p-2">
                        <i className="mdi mdi-account text-dirty"></i>
                      </span>
                      <p className="mb-0 navbar-profile-name">
                        <span name="user-name">{user.name}</span>
                      </p>
                      <i
                        id="user-dropdown-toggle"
                        className="mdi mdi-menu-down"
                        name='dropdown'
                      ></i>
                    </div>
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu className="navbar-dropdown preview-list navbar-profile-dropdown-menu">
                  <h6 className="p-3 mb-0">
                    <span>Profile</span>
                  </h6>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    as={Link}
                    to="/settings"
                    className="preview-item"
                    name="settings"
                  >
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-settings text-success"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject mb-1">
                        <span>Settings</span>
                      </p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    onClick={onLogout}
                    className="preview-item"
                    name="logout"
                  >
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-logout text-danger"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject mb-1">
                        <span>Log Out</span>
                      </p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                </Dropdown.Menu>
              </Dropdown>
            )}
          </ul>
        </div>
      </nav>
    );
};

Navbar.propTypes = {
  data: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  data: state.auth,
});

export default connect(mapStateToProps, { logout })(withRouter(Navbar));
