import axios from 'axios';
import {
  REGISTER_SUCCESS,
  LOGIN_SUCCESS,
  USER_LOADED,
  LOADING_LOGIN,
  LOADING_REGISTER,
  LOADING_USER,
  SET_AUTH_ALERT,
  LOADING_SEND_VERIFICATION_EMAIL,
  SEND_VERIFICATION_EMAIL_SUCCESS,
  SET_AUTH_MESSAGE,
  CLEAR_AUTH_MESSAGE,
  CLEAR_AUTH_MESSAGES,
  LOADING_VERIFICATION,
  VERIFICATION_SUCCESS,
  LOADING_TOKEN_VALIDATION,
  TOKEN_VALIDATION_COMPLETE,
  TOKEN_VALIDATION_FAILED,
  LOADING_SEND_EMAIL,
  SEND_EMAIL_SUCCESS,
  RESET_SUCCESS,
  SET_AUTH_STATE,
  LOADING_PROFILE_CHANGE,
  PROFILE_CHANGED,
  UPDATE_DEFAULT_SETTINGS,
} from './types';

//globals
const url = process.env.REACT_APP_URL;
const serverError =
  'An error was encountered while processing your request. We apologize for the inconvenience. Please try again at a later time.';

const handleError = (err, dispatch) => {
  if (err.response !== undefined) {
    //except any errors from the server
    dispatch({
      type: SET_AUTH_ALERT,
      payload: err.response.data.msg,
    });
    setTimeout(() => {
      dispatch({
        type: SET_AUTH_ALERT,
        payload: null,
      });
    }, 5000);
  } else {
    //except any errors from the server
    dispatch({
      type: SET_AUTH_ALERT,
      payload: serverError,
    });
    setTimeout(() => {
      dispatch({
        type: SET_AUTH_ALERT,
        payload: null,
      });
    }, 5000);
  }
};

//load user
export const loadUser = () => async (dispatch) => {
  try {
    dispatch({
      type: LOADING_USER,
    });
    const res = await axios.get(`${url}/auth`);

    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: SET_AUTH_ALERT,
      payload: null,
    });
  }
};

//register user
export const register = (formData) => async (dispatch) => {
  if (formData.password2 !== formData.password) {
    dispatch({
      type: SET_AUTH_ALERT,
      payload:
        'Entered passwords do not match. Please ensure passwords match before trying again',
    });
    setTimeout(() => {
      dispatch({
        type: SET_AUTH_ALERT,
        payload: null,
      });
    }, 5000);
  } else {
    try {
      dispatch({
        type: LOADING_REGISTER,
      });

      const res = await axios.post(`${url}/register`, formData);

      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      handleError(err, dispatch);
    }
  }
};

//login user
export const login = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: LOADING_LOGIN,
    });

    const res = await axios.post(`${url}/login`, formData);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    handleError(err, dispatch);
  }
};

//logout user
export const logout = () => async () => {
  await axios.post(`${url}/logout`, { loggedOut: true });
  window.location.href = '/login';
};
//Change email
export const changeProfile = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: LOADING_PROFILE_CHANGE,
    });

    const res = await axios.put(`${url}/change-profile`, formData);
    dispatch({
      type: PROFILE_CHANGED,
      payload: res.data,
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

//Change password
export const changePassword = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: LOADING_PROFILE_CHANGE,
    });

    const res = await axios.put(`${url}/change-password`, formData);
    return res;
  } catch (err) {
    return err.response;
  }
};

// Send a password recovery email
export const sendRecoveryEmail = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: LOADING_SEND_EMAIL,
    });

    await axios.post(`${url}/recover-password`, formData);

    dispatch({
      type: SEND_EMAIL_SUCCESS,
    });
    return true;
  } catch (err) {
    handleError(err, dispatch);
    return false;
  }
};

// Reset a password
export const resetPassword = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_AUTH_STATE,
      key: 'loadingReset',
      value: true,
    });
    await axios.post(`${url}/reset-password`, formData);
    dispatch({
      type: RESET_SUCCESS,
    });
    return true;
  } catch (err) {
    dispatch({
      type: SET_AUTH_STATE,
      key: 'loadingReset',
      value: false,
    });
    return err.response ? err.response.data.msg : serverError;
  }
};

// Reset a password
export const updateDefaultSettings = (form) => async (dispatch) => {
  try {
    dispatch({
      type: SET_AUTH_STATE,
      key: 'updatingDefaultSettings',
      value: true,
    });
    await axios.put(`${url}/default-settings`, form);
    dispatch({
      type: UPDATE_DEFAULT_SETTINGS,
      payload: form,
    });
    return true;
  } catch (err) {
    dispatch({
      type: SET_AUTH_STATE,
      key: 'updatingDefaultSettings',
      value: false,
    });
    return err.response && err.response.data.msg
      ? err.response.data.msg
      : serverError;
  }
};

// Validate a URL token for access to reset/verification
export const validateURLToken = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: LOADING_TOKEN_VALIDATION,
    });

    const res = await axios.post(`${url}/validate-token`, formData);

    dispatch({
      type: TOKEN_VALIDATION_COMPLETE,
      payload: res.data['valid'],
    });
  } catch {
    dispatch({
      type: TOKEN_VALIDATION_FAILED,
    });
  }
};

// Resend email verification
export const resendVerification = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: LOADING_SEND_VERIFICATION_EMAIL,
    });

    await axios.post(`${url}/send-email-verification`, formData);

    dispatch({
      type: SEND_VERIFICATION_EMAIL_SUCCESS,
    });

    dispatch({
      type: SET_AUTH_MESSAGE,
      payload: 'The verification email has been resent.',
    });

    setTimeout(() => {
      dispatch({
        type: CLEAR_AUTH_MESSAGE,
      });
    }, 5000);
  } catch (err) {
    handleError(err, dispatch);
  }
};

// Set the auth message, usually a success message. Green.
export const setMessage = (message) => async (dispatch) => {
  try {
    dispatch({
      type: SET_AUTH_MESSAGE,
      payload: message,
    });

    setTimeout(() => {
      dispatch({
        type: CLEAR_AUTH_MESSAGE,
      });
    }, 5000);
  } catch (err) {
    handleError(err, dispatch);
  }
};

// Clear the auth message.
export const clearMessage = () => async (dispatch) => {
  try {
    dispatch({
      type: CLEAR_AUTH_MESSAGE,
    });
  } catch (err) {
    handleError(err, dispatch);
  }
};
//clear all auth messages
export const clearAllMessages = () => async (dispatch) => {
  try {
    dispatch({
      type: CLEAR_AUTH_MESSAGES,
    });
  } catch (err) {
    handleError(err, dispatch);
  }
};

// Update database entry for user with isVerified=true
export const verifyEmail = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: LOADING_VERIFICATION,
    });

    await axios.post(`${url}/verify-email`, formData);

    dispatch({
      type: VERIFICATION_SUCCESS,
    });
  } catch (err) {
    handleError(err, dispatch);
  }
};

export const setAuthState = (key, value) => async (dispatch) => {
  try {
    dispatch({
      type: SET_AUTH_STATE,
      key: key,
      value: value,
    });
  } catch { }
};
