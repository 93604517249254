import {
  REGISTER_SUCCESS,
  USER_LOADED,
  SET_AUTH_ALERT,
  LOGIN_SUCCESS,
  LOADING_LOGIN,
  LOADING_REGISTER,
  LOADING_USER,
  LOADING_SEND_VERIFICATION_EMAIL,
  SEND_VERIFICATION_EMAIL_SUCCESS,
  SET_AUTH_MESSAGE,
  CLEAR_AUTH_MESSAGE,
  CLEAR_AUTH_MESSAGES,
  LOADING_TOKEN_VALIDATION,
  TOKEN_VALIDATION_COMPLETE,
  TOKEN_VALIDATION_FAILED,
  LOADING_VERIFICATION,
  VERIFICATION_SUCCESS,
  VERIFICATION_FAILURE,
  LOADING_SEND_EMAIL,
  SEND_EMAIL_SUCCESS,
  RESET_SUCCESS,
  SET_AUTH_STATE,
  PROFILE_CHANGED,
  UPDATE_DEFAULT_SETTINGS,
} from '../actions/types';

const initialState = {
  isAuthenticated: false,
  user: null,
  loading: true,
  loadingQuotes: false,
  loadingLogin: false,
  loadingRegister: false,
  loadingReset: false,
  loadingRecovery: false,
  registerSuccess: null,
  alertMsg: null,
  tokenValid: null,
  emailVerified: null,
  emailSent: null,
  message: null,
  verifyingToken: true,
  updatingDefaultSettings: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOADED:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        loadingLogin: false,
        loadingRegister: false,
        loading: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        loadingLogin: false,
        alertMsg: null,
        loading: false,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        alertMsg: null,
        loadingRegister: false,
        registerSuccess: true,
      };
    case PROFILE_CHANGED:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        loadingRegister: false,
        loading: false,
      };

    case UPDATE_DEFAULT_SETTINGS:
      return {
        ...state,
        user: { ...state.user, defaultSettings: action.payload },
        updatingDefaultSettings: false,
      };

    case LOADING_SEND_VERIFICATION_EMAIL:
      return {
        ...state,
        verificationEmailSent: false,
      };

    case SEND_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
        verificationEmailSent: true,
      };

    case SET_AUTH_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };

    case CLEAR_AUTH_MESSAGE:
      return {
        ...state,
        message: null,
      };
    case CLEAR_AUTH_MESSAGES:
      return {
        ...state,
        alertMsg: null,
        warningMessage: null,
        message: null,
      };

    case LOADING_TOKEN_VALIDATION:
      return {
        ...state,
        verifyingToken: true,
      };

    case TOKEN_VALIDATION_COMPLETE:
      return {
        ...state,
        verifyingToken: false,
        tokenValid: action.payload,
      };
    case TOKEN_VALIDATION_FAILED:
      return {
        ...state,
        verifyingToken: false,
        tokenValid: null,
      };

    case LOADING_VERIFICATION:
      return {
        ...state,
        loading: true,
        emailVerified: null,
      };

    case VERIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        emailVerified: true,
      };

    case VERIFICATION_FAILURE:
      return {
        ...state,
        loading: false,
        emailVerified: false,
      };

    case LOADING_SEND_EMAIL:
      return {
        ...state,
        emailSent: false,
        loadingRecovery: true,
      };
    case SEND_EMAIL_SUCCESS:
      return {
        ...state,
        emailSent: true,
        loadingRecovery: false,
      };

    case SET_AUTH_ALERT:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        loadingRegister: false,
        loadingLogin: false,
        user: null,
        loadingRecovery: false,
        alertMsg: action.payload,
      };

    case LOADING_USER:
      return {
        ...state,
        loading: true,
      };
    case LOADING_LOGIN:
      return {
        ...state,
        loadingLogin: true,
      };

    case LOADING_REGISTER:
      return {
        ...state,
        loadingRegister: true,
      };
    case RESET_SUCCESS:
      return {
        ...state,
        loadingReset: false,
        message: 'Password reset complete! Redirecting to the login page...',
      };

    case SET_AUTH_STATE:
      return {
        ...state,
        [action.key]: action.value,
      };

    default:
      return state;
  }
};
export default reducer;
