import { LOADING_MATRIX, GET_MATRIX, SET_MATRIX_ALERT } from '../actions/types';

const initialState = {
  matrixData: null,
  loadingMatrix: false,
  matrixForm: null,
  matrixAlertMsg: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MATRIX:
      //We have to make sure the matrices are in the correct order each matrice has a comparator column in which the order should be reflected
      let arr = [];
      //first lets find the order of comparitors
      let comparators = ['Comparator'];
      action.payload.data['Standardized Residuals'].forEach((row) => {
        comparators.push(row.Comparator);
      });
      Object.keys(action.payload.data).forEach((matrix) => {
        action.payload.data[matrix].forEach((row) => {
          let dic = {};
          comparators.forEach((key) => {
            dic[key] = row[key];
          });
          arr.push(dic);
        });
        action.payload.data[matrix] = arr;
        arr = [];
      });
      return {
        ...state,
        matrixForm: action.form,
        matrixData: action.payload,
        loadingMatrix: false,
      };
    case LOADING_MATRIX:
      return {
        ...state,
        loadingMatrix: true,
      };

    case SET_MATRIX_ALERT:
      return {
        ...state,
        matrixAlertMsg: action.payload,
        loadingMatrix: false,
      };
    default:
      return state;
  }
};

export default reducer;
